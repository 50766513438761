export const env = process.env.NODE_ENV;
export const ClientToken = "nzl5MSP6eW"

const getAPIPrefix = () => {
    let host = window.location.host
    let apiPrefix = "qa-api"
    switch (host) {
        case "platform.mrkt365.com":
        case "prod-platform.mrkt365.com":
            apiPrefix = "api"
            break
        case "uat-platform.mrkt365.com":
            // apiPrefix = "uat-api"
            break
        default:
            break
    }
    return apiPrefix;
}
const apiPrefix = getAPIPrefix()

const localHost = "https://qa-api.platform.mrkt365.com/";
const API_URL = "https://" + apiPrefix + ".platform.mrkt365.com/";

export const CURRENT_URL = window.location.origin + "/";

// mrkt365user
const DEV_MRKT365User_Endpoint = localHost + "mrkt365user/api/v1/";
const MRKT365User_Endpoint = API_URL + "mrkt365user/api/v1/";

// mrkt365vendor
const DEV_MRKT365Vendor_Endpoint = localHost + "mrkt365vendor/api/v1/";
const MRKT365Vendor_Endpoint = API_URL + "mrkt365vendor/api/v1/";

// mrkt365project
const DEV_MRKT365Project_Endpoint = localHost + "mrkt365project/api/v1/";
const MRKT365Project_Endpoint = API_URL + "mrkt365project/api/v1/";
const DEV_MRKT365Projectv2_Endpoint = localHost + "mrkt365project/api/v2/";
const MRKT365Projectv2_Endpoint = API_URL + "mrkt365project/api/v2/";

// mrkt365payment
const DEV_MRKT365Payment_Endpoint = localHost + "mrkt365payment/api/v1/";
const MRKT365Payment_Endpoint = API_URL + "mrkt365payment/api/v1/";

// mrkt365seo3p
const DEV_MRKT365SEO3p_Endpoint = localHost + "mrkt365seo3p/api/v1/";
const MRKT365SEO3p_Endpoint = API_URL + "mrkt365seo3p/api/v1/";

// mrkt365ai
const DEV_MRKT365AI_Endpoint = localHost + "mrkt365ai/api/v1/";
const MRKT365AI_Endpoint = API_URL + "mrkt365ai/api/v1/";

export const userEndpoint = env === "development" ? DEV_MRKT365User_Endpoint : MRKT365User_Endpoint;
export const vendorEndpoint = env === "development" ? DEV_MRKT365Vendor_Endpoint : MRKT365Vendor_Endpoint;
export const projectEndpoint = env === "development" ? DEV_MRKT365Project_Endpoint : MRKT365Project_Endpoint;
export const projectV2Endpoint = env === "development" ? DEV_MRKT365Projectv2_Endpoint : MRKT365Projectv2_Endpoint
export const paymentEndpoint = env === "development" ? DEV_MRKT365Payment_Endpoint : MRKT365Payment_Endpoint;
export const seo3pEndpoint = env === "development" ? DEV_MRKT365SEO3p_Endpoint : MRKT365SEO3p_Endpoint;
export const aiEndpoint = env === "development" ? DEV_MRKT365AI_Endpoint : MRKT365AI_Endpoint;


export const projectWebSocketEndpoint = "wss://" + apiPrefix + ".platform.mrkt365.com/mrkt365project/ws/"
export const userWebSocketEndpoint = "wss://" + apiPrefix + ".platform.mrkt365.com/mrkt365user/ws/"
export const chatWebsocketUrl = projectWebSocketEndpoint + "chat/";
export const unreadChatsWebsocketUrl = projectWebSocketEndpoint + "unread_chats_count/";
export const chatDashboardWebsocketUrl = projectWebSocketEndpoint + "chat_dashboard/";
export const trueProductionFlag = apiPrefix === "api" ? true : false;
export const StunServerIPnHost = "3.98.80.164:3478"
